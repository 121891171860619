import React, { useState, Suspense, useEffect, useCallback, useRef } from 'react';
//import { Form, Input, Button, Row, Col, Select, Skeleton } from 'antd';
import { Form, Input, Button, Row, Col, Select, Modal, notification } from 'antd';
import Header from './Header';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { Slider } from '../../../../components/slider/slider';
import { ProjectCard } from '../../../../container/project/style';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../../../components/heading/heading';
import { Main } from '../../../styled';
import FeatherIcon from 'feather-icons-react';
import hostimage from '../../../../static/img/hostimg.jpg';
import useInput from "../../../../container/profile/authentication/overview/useInput";
import LanguageList from '../../../../StaticData/languages.json'
import axios from 'axios';
import FbSignIn from './FbSignIn';
import { AuthWrapper } from './style';
import PhoneInput from 'react-phone-input-2';
import ReCAPTCHA from "react-google-recaptcha";
import CommonFooter from './CommonFooter';
import styled from "styled-components";
import { alertModal } from '../../../../components/modals/antd-modals';
import { UserOutlined, LinkedinFilled, FacebookFilled, YoutubeFilled, CloseOutlined, InstagramFilled } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;

const PlayerWaitlist = () => {

    const [form] = Form.useForm();
    const [mobile, setMobile] = useState('');
    const [buttonDisable, setButton] = useState(false);
    const [loading, setLoading] = useState(false);

    const [recaptcha, setRecaptcha] = useState('')


    let address = useInput('');

    const handleCountryCode = (value, data, event, formattedValue) => {
        setMobile(formattedValue);
    }

    function onChange1(value) {
        setRecaptcha(value);
        setButton(true);
    }

    const sendData = values => {

        const formData = new FormData();
        //const post_data = { ...values, phone: mobile, address: address.addressData.place_name, address_coordinate: address.addressData.center, languagesSpoken: values.languagesSpoken.join(','), waitlist_type: 'via the link' };
        const post_data = { ...values, phone: mobile, languages_spoken: values.languages_spoken.join(','), waitlist_type: 'via the link' };
        formData.append('data', JSON.stringify(post_data));

        const promise = new Promise((resolve, reject) => {

            let verify_token = { token: recaptcha };
            axios.post(`${process.env.REACT_APP_API_URL}/send-recaptcha-token`, verify_token)
                .then(function (response) {
                    if (response.data.google_response.success == true) {

                        resolve(true);
                    } else {
                        // alert(response.data.message)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
        promise.then(async (d) => {

            axios.post(`${process.env.REACT_APP_API_URL}/create-player-waitlist`, post_data)
            .then((response) => {
                if (response.status == 200) {
                    alertModal.success({
                        title: 'Thank you for applying to our waitlist. We will be in touch shortly!',
                        content: ``,
                        onOk: function () {
                            // Refresh the page
                            location.reload();
                        }
                    });
                } else {
                    message.error("Please try again", process.env.REACT_APP_NOTIFICATION_DELAY)
                    setButton(false);
                    setLoading(false);
                }

            })
            .catch((error) => {
                console.log("Error :", error)
                setButton(false);
                setLoading(false);
            })

        });
    };


    const sendData1 = (values) => {
        const post_data = { ...values, phone: mobile, address: address.addressData.place_name, address_coordinate: address.addressData.center, languagesSpoken: values.languagesSpoken.join(',') };
        console.log("post_data", post_data);
        axios.post(`${process.env.REACT_APP_API_URL}/create-host-waitlist`, post_data)
            .then((response) => {
                if (response.status == 200) {
                    alertModal.success({
                        title: 'Thank you for applying to our waitlist. We will be in touch shortly!',
                        content: ``,
                        onOk: function () {
                            // Refresh the page
                            location.reload();
                        }
                    });
                } else {
                    message.error("Please try again", process.env.REACT_APP_NOTIFICATION_DELAY)
                    setButton(false);
                    setLoading(false);
                }

            })
            .catch((error) => {
                console.log("Error :", error)
                setButton(false);
                setLoading(false);
            })
    }

    return (

        <div>
            <div className="homepage_section">
                <div className="background_gradient">
                    <Header />
                    <AuthWrapper>
                        <Row style={{ margin: '40px 10px' }}>
                            <Col xxl={14} xl={14} lg={14} md={18} xs={24}>
                                <div className="adjust_padding" style={{ padding: '40px', backgroundColor: '#f6f6f4', borderRadius: 20 }}>
                                    <div style={{ fontSize: '30px', marginBottom: 20, justifyContent: 'center', display: 'flex' }} className="demo_h2 title">Join the CrowdServe Player Waitlist</div>
                                    <Row style={{ justifyContent: 'center' }}>
                                        <Form form={form} name="createProject" onFinish={sendData} layout="vertical">
                                            <Row gutter={30}>
                                                <Col md={12} xs={24}>
                                                    <Form.Item
                                                        name="first_name"
                                                        label="First Name"
                                                        rules={[{ required: true, message: 'First Name required!' }]}
                                                    >
                                                        <Input placeholder="First Name" />
                                                    </Form.Item>
                                                </Col>

                                                <Col md={12} xs={24}>
                                                    <Form.Item
                                                        name="last_name"
                                                        label="Last Name"
                                                        rules={[{ required: true, message: 'Last Name required!' }]}
                                                    >
                                                        <Input placeholder="Last Name" />
                                                    </Form.Item>
                                                </Col>


                                                <Col md={24} xs={24}>
                                                    <Form.Item
                                                        name="phone"
                                                        label="Phone Number"
                                                        rules={[{ required: true, message: 'Please input your phone!' }]}
                                                    >
                                                        <PhoneInput
                                                            country={'us'}
                                                            inputProps={{
                                                                name: 'phone'
                                                            }}
                                                            inputStyle={{
                                                                height: '48px',
                                                                width: '100%'
                                                            }}
                                                            enableSearch={true}
                                                            copyNumbersOnly={false}
                                                            searchPlaceholder='search'
                                                            //searchNotFound='No entries to show'
                                                            //countryCodeEditable={false}
                                                            onChange={handleCountryCode}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name="email"
                                                        label="Email Address"
                                                        rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                                                    >
                                                        <Input placeholder="name@example.com" />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name="confirm_email"
                                                        label="Confirm Email Address"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Confirmation of email address required!',
                                                                type: 'email'
                                                            },
                                                            ({ getFieldValue }) => ({
                                                                validator(_, value) {
                                                                    if (!value || getFieldValue('email') === value) {
                                                                        return Promise.resolve();
                                                                    }
                                                                    return Promise.reject(new Error('The two email addresses you entered do not match!'));
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <Input placeholder="Enter email" type="email" />
                                                    </Form.Item>


                                                    <Form.Item
                                                        name="nationality"
                                                        label="Nationality"
                                                        rules={[{ required: true, message: 'Nationality required!' }]}
                                                    >
                                                        <Input placeholder="Nationality" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="ranking"
                                                        label="ITF or ATP Ranking"
                                                        tooltip={{
                                                            title: <p>If you do not have a ranking, please leave blank.</p>,
                                                            icon: <FeatherIcon icon="help-circle" size={18} />,
                                                            overlayClassName: 'ant-tooltip-custom-width'
                                                        }}
                                                    //rules={[{ required: true, message: 'ITF or ATP Ranking required!' }]}
                                                    >
                                                        <Input placeholder="ITF or ATP Ranking" type="number" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="coach"
                                                        label="Coach"
                                                        rules={[{ required: true, message: 'Coach required!' }]}
                                                    >
                                                        <Input placeholder="Coach" />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name="languages_spoken" label="Languages Spoken"
                                                        rules={[{ required: true, message: 'Please select your languages spoken' }]}
                                                    >
                                                        <Select style={{ width: '100%' }}
                                                            size="large" mode="multiple" className="sDash_fullwidth-select" placeholder="Select from dropdown" >
                                                            {
                                                                LanguageList.map((item) =>
                                                                    <Option key={item.name} value={item.name}>{item.name}</Option>
                                                                )
                                                            }
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item
                                                        name="about_yourself" label="Tell us about yourself"
                                                        rules={[{ required: true, message: 'Please input your Tell us about yourself' }]}
                                                        tooltip={{
                                                            title: <p>Add information about hobbies, interests, favorite places to travel, etc.</p>,
                                                            icon: <FeatherIcon icon="help-circle" size={18} />,
                                                            overlayClassName: 'ant-tooltip-custom-width'
                                                        }}
                                                    >
                                                        <TextArea rows={4} placeholder="Tell us about yourself" />
                                                    </Form.Item>



                                                    <Form.Item
                                                        label="Instagram"
                                                        name="instagram"
                                                    >
                                                        <Input prefix={<InstagramFilled />} />
                                                    </Form.Item>

                                                    <Form.Item
                                                        label="X (Twitter)"
                                                        name="twitter"
                                                    >
                                                        <Input prefix={<CloseOutlined />} />
                                                    </Form.Item>

                                                    <Form.Item
                                                        label="Facebook"
                                                        name="facebook"
                                                    >
                                                        <Input prefix={<FacebookFilled />} />
                                                    </Form.Item>

                                                    <Form.Item
                                                        label="LinkedIn"
                                                        name="linkedIn"
                                                    >
                                                        <Input prefix={<LinkedinFilled />} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="YouTube"
                                                        name="youtube"
                                                    >
                                                        <Input prefix={<YoutubeFilled />} />
                                                    </Form.Item>

                                                    <Form.Item>
                                                        <ReCAPTCHA
                                                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                            onChange={onChange1}
                                                        />
                                                    </Form.Item>

                                                </Col>
                                            </Row>

                                            <div>
                                                <Button style={{ marginRight: 10, backgroundColor: 'rgb(6, 105, 17)' }} size="default" htmlType="submit" type="primary" disabled={!buttonDisable}>
                                                    Send {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
                                                </Button>
                                                <Button size="default" type="white" outlined >
                                                    Cancel
                                                </Button>
                                            </div>

                                        </Form>
                                    </Row >
                                </div >
                            </Col>
                        </Row >
                    </AuthWrapper>
                    <CommonFooter />
                </div>
            </div>
        </div>
    );
};

const SuggestionWrapper = styled.div`
  background: #ecedee;
  width: 400px;
  padding: 10px 20px;
  border-radius: 0px 0px 10px 10px;
  width:100%;
`;

const Suggestion = styled.p`
  cursor: pointer;
  max-width: 400px;
  text-align:left;
`;



export default PlayerWaitlist;
