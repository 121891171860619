import { Col, Row, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Footer } from "antd/lib/layout/layout";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';


const CommonFooter = () => {

    const dispatch = useDispatch();

    const UID = useSelector((state) => {
        return state.fb.auth.uid;
    });

    const currentYear = new Date().getFullYear();
    const footerStyle = {
        padding: '15px 10px 15px',
        color: 'rgba(0, 0, 0, 0.65)',
        fontSize: '14px',
        background: '#43b6d921',
        width: '100%',
        boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
    };


    return (
        <>
            <Footer style={footerStyle}>
                <Row>
                    <Col md={12} xs={14}>
                        <span className="mobile-footer-font header-menu-font" style={{ color: 'white' }}>CROWDSERVE © <span style={{color:'#D2F64F'}}>{currentYear}</span></span>
                    </Col>
                    <Col md={12} xs={10}>
                        <div className="float-right-footer mobile-footer-font">
                            {/* <NavLink className="nav-links hamburger-menu-color header-menu-font" to={`#`} style={{ marginLeft: 10 }} >TOS</NavLink> */}
                            <NavLink className="nav-links hamburger-menu-color header-menu-font" to={`#`} style={{ color:'white',marginTop:-10 }} >Privacy Policy</NavLink>
                        </div>
                    </Col>
                </Row>
            </Footer>


        </>
    )

}

export default CommonFooter