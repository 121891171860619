import React, { useState, useEffect, useCallback } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../../../components/modals/antd-modals';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons';
import { AuthWrapper } from './style';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { login } from '../../../../redux/authentication/actionCreator';
import {
  fbAuthLogin,
  fbAuthLoginWithGoogle,
  fbAuthLoginWithFacebook,
} from '../../../../redux/firebase/auth/actionCreator';
import { Checkbox } from '../../../../components/checkbox/checkbox';
import Heading from '../../../../components/heading/heading';
import FbSignIn from './FbSignIn';
import axios from "axios";
import { getFirebase } from 'react-redux-firebase';

const FbSignWithOTP = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading, error, isFbAuthenticate } = useSelector(state => {
    return {
      error: state.firebaseAuth.error,
      isLoading: state.firebaseAuth.loading,
      isFbAuthenticate: state.fb.auth.uid,
    };
  });
  const [form] = Form.useForm();
  const [state, setState] = useState({
    checked: null,
  });
  const [loginModalVisible, setLoginModalVisible] = useState(false);

  const [mobile, setMobile] = useState(null);
  const [visibleOTP, setVisibleOTP] = useState(false);
  const [OTP, setotp] = useState(false);
  const [final, setfinal] = useState('');

  const handleCountryCode = (value, data, event, formattedValue) => {
    setMobile(formattedValue);

  }

  const showLoginModal = () => {
    setLoginModalVisible(true)
  };

  const hideLoginModal = () => {
    setLoginModalVisible(false)
  };

  const handleFbLogin = useCallback(() => {
    dispatch(login());
    history.push('/admin');
  }, [dispatch, history]);

  useEffect(() => {
    if (isFbAuthenticate) {
      handleFbLogin();
    }
  }, [isFbAuthenticate, handleFbLogin]);

  const handleSubmit = async () => {

    const result = await axios.get(`${process.env.REACT_APP_API_URL}/check-phone/${mobile}`);
    if (result != "") {

      if (result.data.status == true) {
        const firebase = getFirebase();
        let verify = new firebase.auth.RecaptchaVerifier('header-recaptcha-container');
        firebase.auth().signInWithPhoneNumber(mobile, verify).then((response) => {
          setfinal(response);
          message.success("OTP is sent to Your Mobile Number", process.env.REACT_APP_NOTIFICATION_DELAY);
          setVisibleOTP(true);
        })
          .catch((err) => {
            message.error(err, process.env.REACT_APP_NOTIFICATION_DELAY);
            setVisibleOTP(false);
            //window.location.reload()
          });
      } else {
        setVisibleOTP(false);
        message.error(result.data.message, process.env.REACT_APP_NOTIFICATION_DELAY);
      }

    } else {
      setVisibleOTP(false);
      message.error("Something went to wrong", process.env.REACT_APP_NOTIFICATION_DELAY);
    }
  };

  const onChange = checked => {
    setState({ ...state, checked });
  };
  console.log(isLoading);

  const ValidateOtp = () => {
    if (OTP === null || final === null) {
      return;
    } else {
      final.confirm(OTP).then((result) => {
        message.success("Login Successfully", process.env.REACT_APP_NOTIFICATION_DELAY);
      }).catch((err) => {
        message.error("Wrong code", process.env.REACT_APP_NOTIFICATION_DELAY);
      })
    }
  }

  return (
    <>

      <AuthWrapper>
        <p className="auth-notice">
          Don&rsquo;t have an account? <NavLink to="/fbRegister">Sign up now</NavLink>
        </p>
        <div className="auth-contents">
          <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
            <Heading as="h3">Sign Into CrowdServe</Heading>

            <Form.Item
              name="phone"
              initialValue=""
              label="Enter Phone number"
              rules={[{ required: true, message: 'Phone number required!' }]}
            >
              <PhoneInput
                country={'us'}
                inputProps={{
                  name: 'phone'
                }}
                inputStyle={{
                  height: '48px',
                  width: '100%'
                }}
                value={mobile}
                enableSearch={true}
                copyNumbersOnly={false}
                searchPlaceholder='search'
                onChange={handleCountryCode}
              />
            </Form.Item>

            {!visibleOTP &&
              <div style={{ paddingTop: '10px', paddingBottom: '10px' }} id="header-recaptcha-container">
              </div>
            }

            {visibleOTP &&
              <Form.Item name="OTP"
                initialValue=""
                label="Enter OTP"
                rules={[{ required: true, message: 'OTP number required!' }]}
              >
                <Input placeholder="Enter your OTP"
                  onChange={(e) => { setotp(e.target.value) }}
                />
              </Form.Item>
            }

            {error ? <p>{error.message}</p> : null}
            <Form.Item style={{ paddingTop: '5px' }}>
              {!visibleOTP &&
                <Button style={{ background: "#008455" }} className="btn-signin" htmlType="submit" type="primary" size="large">
                  {isLoading ? 'Loading...' : 'Get Code'}
                </Button>}

              {visibleOTP &&
                <Button
                  className="btn-proceed" type="primary" size="large"
                  style={{ width: '30%' }}
                  onClick={ValidateOtp}
                >Verify</Button>}

              {'  '}
              <Button className="btn-signin grey-btn"
                type="dark" size="large" onClick={showLoginModal}>
                Login With Email
              </Button>
            </Form.Item>
            {/* <p className="form-divider">
            <span>Or</span>
          </p> */}

            {/* <ul className="social-login">
            <li>
              <Link onClick={() => dispatch(fbAuthLoginWithGoogle())} className="google-signup" to="#">
                <img src={require('../../../../static/img/google.png')} alt="" />
                <span>Sign in with Google</span>
              </Link>
            </li>
            <li>
              <Link onClick={() => dispatch(fbAuthLoginWithFacebook())} className="facebook-sign" to="#">
                <FacebookOutlined />
              </Link>
            </li>
            <li>
              <Link className="twitter-sign" to="#">
                <TwitterOutlined />
              </Link>
            </li>
          </ul> */}
          </Form>
        </div>
      </AuthWrapper>

      {loginModalVisible == true && <FbSignIn onCancel={hideLoginModal} visible={loginModalVisible} />}
    </>

  );
};

export default FbSignWithOTP;
