import { useState } from "react";

const useInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);
    const [suggestions, setSuggestions] = useState([]);
    const [addressData, setAddressData] = useState([]);

    const handleChange = async (event) => {
        setValue(event.target.value);

        try {
            const token = 'pk.eyJ1IjoiZ29nZ2xlcyIsImEiOiJja2F3eXNxb2EwMGhvMnVwOXp5cjk0ZDRjIn0.CbQzBQjFB52iSwswvOpdpg';

            const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${event.target.value}.json?access_token=${token}&autocomplete=true`;

            const response = await fetch(endpoint);
            const results = await response.json();
            setSuggestions(results?.features);
        } catch (error) {
            console.log("Error fetching data, ", error);
        }
    };
    const clear = () => {
        setValue("");
        setSuggestions([]);
        setAddressData([]);
    };

    return {
        value,
        onChange: handleChange,
        setValue,
        suggestions,
        setSuggestions,
        setAddressData,
        addressData,
        clear
    };

};

export default useInput;
