import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Form, Input, Button, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons';
import { AuthWrapper } from './style';
import { login } from '../../../../redux/authentication/actionCreator';
import {
  fbAuthLogin,
  fbAuthLoginWithGoogle,
  fbAuthLoginWithFacebook,
} from '../../../../redux/firebase/auth/actionCreator';
import ReCAPTCHA from "react-google-recaptcha";
import { Modal } from '../../../../components/modals/antd-modals';
import { Checkbox } from '../../../../components/checkbox/checkbox';
import Heading from '../../../../components/heading/heading';
import axios from 'axios';

const FbSignIn = ({ visible, onCancel }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading, error, isFbAuthenticate } = useSelector(state => {
    return {
      error: state.firebaseAuth.error,
      isLoading: state.firebaseAuth.loading,
      isFbAuthenticate: state.fb.auth.uid,
    };
  });
  const recaptchaRef = useRef(null);

  const [form] = Form.useForm();
  const [state, setState] = useState({
    checked: false,
    visible,
  });

  const handleFbLogin = useCallback(() => {
    dispatch(login());
    history.push('/admin');
  }, [dispatch, history]);

  useEffect(() => {
    if (isFbAuthenticate) {
      handleFbLogin();
    }
  }, [isFbAuthenticate, handleFbLogin]);

  const handleSubmit = values => {

    //dispatch(fbAuthLogin(values));
    let verify_token = { token: recaptcha };
    axios.post(`${process.env.REACT_APP_API_URL}/send-recaptcha-token`, verify_token)
      .then(function (response) {
        if (response.data.google_response.success == true) {
          //
          if (state.checked != true) {
            dispatch(fbAuthLogin(values));
          } else {
            sendOTP(values.email)
            setRecaptcha('');
            window.grecaptcha.reset();
          }

        } else {
          setRecaptcha('');
          setVerified(false);
          window.grecaptcha.reset();
          alert("Recaptcha invalid please try again")
          return false;
        }
      })
      .catch(function (error) {
        window.grecaptcha.reset()
        console.log(error);
      });
  };

  const resetReCAPTCHA = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const sendOTP = values => {

    let post_data = { email: values };
    axios.post(`${process.env.REACT_APP_API_URL}/send-otp`, post_data)
      .then(function (response) {
        if (response.data) {
          if (response.data.status == true) {
            notification.success({
              message: response.data.message
            });
            setvisiableOTP(true);
          } else {
            notification.warning({
              message: response.data.message
            });
            resetReCAPTCHA();
            setvisiableOTP(false);
            //window.grecaptcha.reset();
          }
        }
        else {
          resetReCAPTCHA();
          notification.danger({
            message: "something went wrong"
          });
          setvisiableOTP(false);
        }
      })
      .catch(function (error) {
        console.log("error", error.message);
        setvisiableOTP(false);
      });
  }

  const onChange = checked => {
    setState({ ...state, checked });
  };

  const [recaptcha, setRecaptcha] = useState('')
  const [verified, setVerified] = useState(false);
  const [message, setMessage] = useState(false);
  const [email, setEmail] = useState(null);
  const [visiableOTP, setvisiableOTP] = useState(false);


  function onChange1(value) {

    setRecaptcha(value);
    setVerified(true);
  }

  const handleCancel = () => {
    onCancel();
  };

  const handleVerify = values => {
    let post_data = { ...values, email: email };

    axios.post(`${process.env.REACT_APP_API_URL}/verify-otp`, post_data)
      .then(function (response) {
        if (response.data) {
          if (response.data.status == true) {
            dispatch(fbAuthLogin(response.data));
          } else {
            notification.warning({
              message: response.data.message
            });
          }
        }
        else {
          notification.warning({
            message: "something went wrong"
          });

        }
      })
      .catch(function (error) {
        console.log("error", error.message);
        setvisiableOTP(false);
      });

  }


  console.log(isLoading);
  return (
    <>
      <Modal
        type={state.modalType}
        title="Sign In"
        visible={state.visible}
        footer={[]}
        onCancel={handleCancel}
      >
        <AuthWrapper>
          <div className="auth-contents">
            {visiableOTP == true ?
              <>
                <Form name="login" form={form} onFinish={handleVerify} layout="vertical">
                  <Heading as="h3">
                    Authentication required
                  </Heading>
                  <Form.Item
                    name="otp"
                    rules={[{ message: 'Please input your otp', required: true },
                    { min: 6, message: "Please enter OTP of length 6" },
                    { max: 6, message: "Please enter OTP of length 6" },]}
                    label="Enter OTP"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item>
                    <Button className="btn-signin" htmlType="submit" type="primary" size="large">
                      {isLoading ? 'Loading...' : 'Verify'}
                    </Button>
                    <Link to="#" onClick={(e) => sendOTP(email)} style={{ marginLeft: 20 }}>Resend OTP</Link>
                  </Form.Item>
                </Form>
              </>
              :
              <>
                <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
                  <Heading as="h3">Sign Into CrowdServe </Heading>
                  <Form.Item
                    name="email"
                    rules={[{ message: 'Please input your Email!', required: true }]}
                    label="Email Address"
                  >
                    <Input onChange={(e) => setEmail(e.target.value)} />
                  </Form.Item>
                  {state.checked != true &&
                    <>
                      <Form.Item
                        rules={[{ message: 'Please input your password!', required: true }]}
                        name="password"
                        label="Password"
                      >
                        <Input.Password placeholder="Password" />
                      </Form.Item>
                    </>
                  }
                  <Form.Item>
                    <Checkbox onChange={onChange} checked={state.checked}>Login without password</Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={onChange1}
                    />
                  </Form.Item>

                  <div className="auth-form-action">
                    <NavLink className="forgot-pass-link" to="/forgotPassword">
                      Forgot password?
                    </NavLink>
                  </div>
                  {error ? <p>{error.message}</p> : null}
                  <Form.Item>
                    <Button style={{ background: '#008455' }} className="btn-signin" htmlType="submit" type="primary" size="large" disabled={!verified}>
                      {state.checked === true
                        ? isLoading
                          ? 'Loading...'
                          : 'Send'
                        : isLoading
                          ? 'Loading...'
                          : 'Sign In'}
                    </Button>
                  </Form.Item>
                </Form>

              </>}
          </div>
        </AuthWrapper>
      </Modal>
    </>
  );
};

export default FbSignIn;
