
import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Select, Badge, Menu, Dropdown, Layout, Avatar } from 'antd';
import greenball from '../../../../static/img/greenball.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../../../components/modals/antd-modals';
import FbSignWithOTP from './FbSignWithOTP';
import { InfoWraper, NavAuth, UserDropDwon } from '../../../../components/utilities/auth-info/auth-info-style';
import { useHistory, Link, NavLink, useRouteMatch } from 'react-router-dom';
// import FbSignIn from './overview/FbSignIn';
import { Popover } from '../../../../components/popup/popup';
import './navBar.css';
import FeatherIcon from 'feather-icons-react';
import Hamburger from '../../../../static/img/hamburger-Icon.svg'
import CloseIcon from '../../../../static/img/close-Icon.svg'
import Heading from '../../../../components/heading/heading';
import { logOut } from '../../../../redux/authentication/actionCreator';
import { fbAuthLogout } from '../../../../redux/firebase/auth/actionCreator';
import axios from 'axios';
const { Header, Footer, Sider } = Layout;
export default function HeaderComp() {

    const history = useHistory();
    const dispatch = useDispatch();
    const { path } = useRouteMatch();
    const [profile_name, setProfileName] = useState('')
    const [loginModalVisible, setLoginModalVisible] = useState(false);

    const { isAuthenticate } = useSelector(state => {
        return {
            isAuthenticate: state.fb.auth.uid,
        };
    });

    const showLoginModal = () => {
        setLoginModalVisible(true)
    };

    const hideLoginModal = () => {
        setLoginModalVisible(false)
    };
    const openAdmin = () => {

        if (isAuthenticate) {
            history.push('/home')
        } else {
            history.push('/')
        }

    }
    const SignOut = async (e) => {
        e.preventDefault();

        // Disable the button to prevent multiple clicks
        e.target.disabled = true;

        // Perform the logout process
        try {
            dispatch(fbAuthLogout(dispatch(logOut())));
            localStorage.clear();
            sessionStorage.clear();
            history.push("/");
            location.reload();
            //window.location.href = '/';
        } catch (error) {
            console.error("Logout error:", error);
            // Handle any errors that occur during logout
        } finally {
            // Re-enable the button
            e.target.disabled = false;
        }
    };

    const [state, setState] = useState({
        signInVisible: false,
    });
    const showModal = () => {
        setState({
            ...state,
            signInVisible: true,
        });
    };
    const [click, setClick] = React.useState(false);
    const handleClick = () => setClick(!click);
    const Close = () => setClick(false);

    useEffect(() => {
        if (isAuthenticate != undefined) {
            axios.get(`${process.env.REACT_APP_API_URL}/get-user-details/${isAuthenticate}`)
                .then((response) => {
                    if (response.data[0]) {
                        setProfileName(response.data[0].first_name + " " + response.data[0].last_name);
                    }
                }).catch((error) => {
                    console.log("Error", error)
                })
        }

    }, []);

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const userContent = (
        <UserDropDwon>
            <div className="user-dropdwon">
                <figure className="user-dropdwon__info">
                    {/* <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" /> */}
                    <figcaption>
                        <Heading as="h5">{profile_name}</Heading>
                    </figcaption>
                </figure>
                <ul className="user-dropdwon__links">
                    <li>
                        <Link to={'/admin/profile/myProfile'} >
                            <FeatherIcon icon="user" /> Profile
                        </Link>
                    </li>
                </ul>
                <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
                    <FeatherIcon icon="log-out" /> Sign Out
                </Link>
            </div>
        </UserDropDwon>
    );



    return (
        <>
            <Header
                style={{
                    height: 72,
                    position: 'fixed',
                    background: !isScrolled ? 'transparent' : '#ffffff',
                    width: '100%',
                    top: 0,
                    padding: '0px',
                    zIndex: 2,
                    boxShadow: "none"

                }}
            >
                <Row>
                    <Col span={24}>
                        <div>
                            <div className={click ? "main-container" : ""} onClick={() => Close()} />
                            <nav className="navbar" onClick={(e) => e.stopPropagation()}>
                                <div className="nav-container">
                                    {
                                        !isAuthenticate ?
                                            <NavLink exact to="/" className="nav-logo" style={{ cursor: 'auto', width: '10%' }}>
                                                <a href="/" style={{ display: 'flex', textDecoration: 'none', position: 'relative', justifyContent: 'space-between', alignItems: 'center' }} onClick={openAdmin}>
                                                    <div style={{ height: '100%', position: 'relative', right: 5, display: 'flex' }}>
                                                        <img
                                                            src={greenball}
                                                            alt="Vercel Logo"
                                                            className="vercelLogo"
                                                            width={20.11}
                                                            height={24}
                                                            left={13.44}
                                                            top={-3.53}
                                                            position={'absolute'}
                                                            transform={'rotate(45deg)'}
                                                            transformOrigin={'0 0'}
                                                            priority
                                                        />
                                                    </div>
                                                    <div style={{ justifyContent: 'flex-start', alignItems: 'center', display: 'flex' }}>
                                                        <div style={{ color: 'white', fontSize: 20, fontFamily: 'Urbanist', fontWeight: '900', lineHeight: 2, wordWrap: 'break-word' }}>CROWD</div>
                                                        <div style={{ color: '#D2F64F', fontSize: 20, fontFamily: 'Urbanist', fontWeight: '900', lineHeight: 2, wordWrap: 'break-word' }}>SERVE</div>
                                                    </div>
                                                </a>
                                            </NavLink>
                                            :
                                            <NavLink exact to="/admin/profile/myProfile" className="nav-logo" style={{ cursor: 'auto', width: '10%' }}>
                                                <a href="/admin/profile/myProfile" style={{ display: 'flex', textDecoration: 'none', position: 'relative', justifyContent: 'space-between', alignItems: 'center' }} onClick={openAdmin}>
                                                    <div style={{ height: '100%', position: 'relative', right: 5, display: 'flex' }}>
                                                        <img
                                                            src={greenball}
                                                            alt="Vercel Logo"
                                                            className="vercelLogo"
                                                            width={20.11}
                                                            height={24}
                                                            left={13.44}
                                                            top={-3.53}
                                                            position={'absolute'}
                                                            transform={'rotate(45deg)'}
                                                            transformOrigin={'0 0'}
                                                            priority
                                                        />
                                                    </div>
                                                    <div style={{ justifyContent: 'flex-start', alignItems: 'center', display: 'flex' }}>
                                                        <div style={{ color: 'white', fontSize: 20, fontFamily: 'Urbanist', fontWeight: '900', lineHeight: 2, wordWrap: 'break-word' }}>CROWD</div>
                                                        <div style={{ color: '#D2F64F', fontSize: 20, fontFamily: 'Urbanist', fontWeight: '900', lineHeight: 2, wordWrap: 'break-word' }}>SERVE</div>
                                                    </div>
                                                </a>
                                            </NavLink>
                                    }


                                    <div style={{ width: '75%' }}>
                                        <ul className={click ? "nav-menu active" : "nav-menu"}>
                                            {/* <li className="nav-item">
                                                <Link
                                                    exact
                                                    to="/about-us"
                                                    activeClassName="active"
                                                    className="nav-links header-menu-name hamburger-menu-color header-menu-font"
                                                    style={{ fontSize: 18, fontWeight: 600 }}
                                                // onClick={() => { handleCollector(),click ? handleClick() : null }}
                                                // onClick={() => { click ? handleClick() : null }}
                                                >
                                                    About Us
                                                </Link>
                                            </li> */}
                                            {/* <li className="nav-item">
                                                <Link
                                                    exact
                                                    to="/find-housing-for-athletes"
                                                    activeClassName="active"
                                                    className="nav-links header-menu-name hamburger-menu-color header-menu-font"
                                                    style={{ fontSize: 18, fontWeight: 600 }}
                                                // onClick={() => { handleCollector(),click ? handleClick() : null }}
                                                // onClick={() => { click ? handleClick() : null }}
                                                >
                                                    Find Housing
                                                </Link>
                                            </li> */}
                                            {/* <li className="nav-item">
                                                <Link
                                                    exact
                                                    to="/host-athletes"
                                                    activeClassName="active"
                                                    className="nav-links header-menu-name hamburger-menu-color header-menu-font"
                                                    style={{ fontSize: 18, fontWeight: 600 }}
                                                // onClick={() => { handleCollector(),click ? handleClick() : null }}
                                                // onClick={() => { click ? handleClick() : null }}
                                                >
                                                    Host Athlete
                                                </Link>
                                            </li> */}
                                            {/* <li className="nav-item">
                                                <Link
                                                    exact
                                                    to="/placeList"
                                                    activeClassName="active"
                                                    className="nav-links header-menu-name hamburger-menu-color header-menu-font"
                                                    style={{ fontSize: 18, fontWeight: 600 }}
                                                // onClick={() => { handleCollector(),click ? handleClick() : null }}
                                                // onClick={() => { click ? handleClick() : null }}
                                                >
                                                    Places
                                                </Link>
                                            </li> */}

                                            {/* <li className="nav-item">
                                                <Link
                                                    exact
                                                    to="/map-listings"
                                                    activeClassName="active"
                                                    className="nav-links header-menu-name hamburger-menu-color header-menu-font"
                                                    style={{ fontSize: 18, fontWeight: 600 }}
                                                // onClick={() => { handleCollector(),click ? handleClick() : null }}
                                                // onClick={() => { click ? handleClick() : null }}
                                                >
                                                    Map Listings
                                                </Link>
                                            </li> */}


                                            <li className="nav-item mobile-login-button">
                                                {
                                                    !isAuthenticate ?
                                                        <button
                                                            className="menu-btn login-circle-btn"
                                                            id="signinbutton"
                                                            onClick={showLoginModal}
                                                            style={{ fontWeight: 600, height: 35, fontSize: 11, textTransform: 'uppercase', marginBottom: 10, marginLeft: 10 }}
                                                        >
                                                            LOG IN/SIGN UP
                                                        </button>
                                                        :
                                                        <div className="nav-author">
                                                            <Popover placement="bottomRight" content={userContent} action="click">
                                                                <Link to="#" className="head-example">
                                                                    <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
                                                                </Link>
                                                            </Popover>
                                                        </div>
                                                }
                                            </li>
                                        </ul>
                                    </div>

                                    <div style={{ width: '15%', textAlign: 'right', marginRight: 50, display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
                                        <div className="nav-icon" onClick={handleClick}>
                                            {click ?
                                                <img src={CloseIcon} alt='close' />
                                                :
                                                <img src={Hamburger} alt='Cart' />
                                            }

                                        </div>
                                        {
                                            !isAuthenticate ?
                                                <button
                                                    className="menu-btn login-circle-btn desktop-login-button"
                                                    id="signinbutton"
                                                    onClick={showLoginModal}
                                                    style={{ fontWeight: 600, height: 35, fontSize: 11, textTransform: 'uppercase', marginBottom: 10, marginLeft: 10 }}
                                                >
                                                    LOG IN/SIGN UP
                                                </button>
                                                :
                                                <div className="nav-author desktop-login-button">
                                                    <Popover placement="bottomRight" content={userContent} action="click">
                                                        <Link to="#" className="head-example">
                                                            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
                                                        </Link>
                                                    </Popover>
                                                </div>
                                        }

                                    </div>


                                </div>
                            </nav>
                        </ div>
                    </Col>
                </Row>

            </Header>
            <Modal
                className="addEvent-modal"
                footer={null}
                type="primary"
                title="Sign In"
                visible={loginModalVisible}
                onCancel={hideLoginModal}
            >
                <FbSignWithOTP />
            </Modal>
        </>

    )
}
